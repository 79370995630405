import { OrderInfoModuleConfigOverride } from '@pv-frontend/order-info';

import { orderInfoConfig } from './order-info';
export const orderInfoDesktop: OrderInfoModuleConfigOverride = {
  orderHistory: {
    ...orderInfoConfig.orderHistory,
    backArrow: {
      ...orderInfoConfig.orderHistory.backArrow,
      navigationPath: ['gvms'],
    },
    chip: {
      style: {
        ...orderInfoConfig.orderHistory.chip.style,
        inActive: {
          ...orderInfoConfig.orderHistory.chip.style?.inActive,
          container: {
            ...orderInfoConfig.orderHistory.chip.style?.inActive.container,
            margin: '0 12px 20px 0',
          },
        },
        active: {
          ...orderInfoConfig.orderHistory.chip.style?.active,
          container: {
            ...orderInfoConfig.orderHistory.chip.style?.active.container,
            margin: '0 12px 20px 0',
          },
        },
      },
    },
  },
  orderStatus: {
    ...orderInfoConfig.orderStatus,
    card: {
      ...orderInfoConfig.orderStatus.card,
      container: {
        ...orderInfoConfig.orderStatus.card.container,
      },
    },
  },
  orderDetails: {
    ...orderInfoConfig.orderDetails,
  },
  paymentStatus: {
    ...orderInfoConfig.paymentStatus,
  },
  voucherCard: {
    ...orderInfoConfig.voucherCard,
  },
};
