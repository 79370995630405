import { OrderInfoModuleConfigOverride } from '@pv-frontend/order-info';

import { redeemButtonStyle } from './buttons.config';
import { chipsStyles } from './chips.config';
import { panelStyles } from './gift-vouchers';

export const orderInfoConfig: OrderInfoModuleConfigOverride = {
  orderHistory: {
    pageLayout: {
      moduleChipsVisible: true,
      chipOptions: [
        {
          displayName: 'Exclusive Bookings',
          paramName: 'ExclusiveBooking',
        },
        {
          displayName: 'Gift Cards',
          paramName: 'GiftCardBooking',
        },
        {
          displayName: 'Memberships',
          paramName: 'MembershipBooking',
        },
        {
          displayName: 'Offers',
          paramName: 'OfferBooking',
        },
      ],
      dateFormat: 'dd MMM yyyy',
    },
    navBar: {
      style: {
        backgroundColor: 'white',
        boxShadow: '0px 1px 3px 0px var(--card-shadow)',
        position: 'relative',
        zIndex: '1',
        fontSize: '17px',
        fontWeight: '700',
      },
    },
    chip: {
      style: chipsStyles,
    },
    backArrow: {
      style: {
        fill: 'var(--primary-color)',
        width: '35px',
        height: '35px',
        boxShadow: '0px 2px 8px 0px var(--card-shadow)',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
      },
      navigationPath: ['gvms'],
    },
    navBarTitle: {
      style: {
        color: '#404040',
        fontSize: '17px',
        fontWeight: '700',
      },
    },
    statusIcon: {
      style: {
        width: '0.85714rem',
        height: '0.85714rem',
      },
    },
    benefitButton: {
      style: {
        // TODO: use from button config
        padding: '.2rem 0',
        fontWeight: '600',
        fontSize: '.86rem',
        color: 'var(--brand-primary-500)',
        borderRadius: '2.2rem',
        boxShadow: '0px 2px 8px 0px var(--button-box-shadow)',
        textTransform: 'uppercase',
        width: '75px',
      },
    },
    card: {
      card: {
        padding: '0.7rem 0',
      },
      title: {
        fontWeight: '700',
        fontSize: '1rem',
        color: 'var(--text-color)',
      },
    },
    divider: {
      style: {
        border: '0',
        borderTop: '.7px solid var(--feedback-border-neutral-low-contrast)',
      },
    },
  },
  orderStatus: {
    buyButton: {
      style: redeemButtonStyle,
    },
    benefitButton: {
      // TODO: use from button config
      style: {
        padding: '.2rem 0',
        fontWeight: '700',
        fontSize: '.86rem',
        color: 'var(--primary-color)',
        borderRadius: '2.2rem',
        boxShadow: '0px 2px 8px 0px var(--button-box-shadow)',
        textTransform: 'uppercase',
        width: '95px',
      },
    },
    card: {
      title: {
        fontWeight: '400',
        fontSize: '0.86rem',
        color: 'var(--text-color)',
      },
      description: {
        color: '#A6A6A6',
      },
      container: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    statusIcon: {
      style: {
        width: '3.42rem',
        height: ' 3.42rem',
      },
    },
    cardStatusIcon: {
      style: {
        lineHeight: '1.8',
      },
    },
    homeNavigationPath: [''],
  },
  paymentStatus: {
    style: {
      statusIcon: {
        success: {
          width: '72px',
          height: '72px',
          fill: 'var(--feedback-background-positive-high-contrast)',
        },
        error: {
          width: '70px',
          height: '70px',
          fill: 'var(--feedback-background-negative-high-contrast)',
        },
      },
    },
  },
  orderDetails: {
    metaInfoDrawerPanel: {
      style: {
        container: {
          padding: '2rem',
        },
        header: {},
        content: {},
      },
    },
    buyButton: {
      style: redeemButtonStyle,
    },
    learnButton: {
      style: {
        color: 'var(--brand-primary-500)',
        padding: '0',
        marginBottom: '2rem',
      },
    },
    statusIcon: {
      style: {
        width: '1.14286rem',
        height: '1.14286rem',
      },
    },
    topBar: {
      style: {
        boxShadow: '0px 1px 3px 0px var(--card-shadow)',
        position: 'relative',
        fontSize: '17px',
        color: 'var(--text-color)',
        fontWeigth: '700',
      },
    },
    backArrow: {
      style: {
        fill: 'var(--primary-color)',
        width: '2.25rem',
        height: '2.25rem',
        padding: '9px',
        display: 'flex',
        borderRadius: '50%',
        boxShadow: '0px 2px 8px 0px var(--card-shadow)',
      },
    },
    panel: {
      style: panelStyles,
    },
    homeNavigationPath: [''],
  },
  voucherCard: {
    voucher: {},
    container: {
      style: {
        success: {
          background:
            'linear-gradient(107deg, #D8107B 1.55%, #D8107B 1.56%, #76194B 53.6%)',
          borderRadius: '12px',
        },
        error: {
          borderRadius: '12px',
          backgroundColor: 'var(--error-background-low-contrast)',
          border: '1px solid var(--error-red)',
          fill: 'var(--error-red)',
          color: 'var(--text-color)',
        },
      },
    },
    redeemButton: {
      style: {
        padding: '0',
        textDecoration: 'underline',
        fontSize: '1rem',
        color: '#F8F9FB',
        minWidth: 'auto',
        fontWeight: 600,
        textTransform: 'uppercase',
      },
    },
    statusIcon: {
      style: {
        width: '1.14286rem',
        height: '1.14286rem',
      },
    },
    divider: {
      style: {
        width: 'calc(100% + 2rem)',
        margin: '1.4rem -1rem 1.9rem',
      },
    },
  },
};
