import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { OverrideStyleConfig } from '@pv-frontend/pv-shared-components/common/types';
import { DeviceService } from '@pv-frontend/pv-shared-services/device-service';
import { Subject, finalize, takeUntil } from 'rxjs';

import {
  BookingResponse,
  GiftCardOrder,
  GiftcardDetailsFlatObj,
  GiftCardBookingResponse,
  GiftCardBooking,
} from '../../interfaces/gv-redemption';
import { CampaignService } from '../../services/campaign/campaign.service';

@Component({
  selector: 'axis-ol-gv-redemption',
  templateUrl: './gv-redemption.component.html',
  styleUrls: ['./gv-redemption.component.scss'],
})
export class GvRedemptionComponent {
  @Input() public campaignId?: string;
  @Input() public giftcard?: GiftcardDetailsFlatObj;
  public isRedeeming: boolean = false;
  public showVoucherRedemptionModal: boolean = true;
  public showVoucherClaimModal: boolean = false;
  public claimButtonStyle: OverrideStyleConfig = {
    height: '3rem',
    width: '136px',
  };
  public retryButtonStyle: OverrideStyleConfig = {
    ...this.claimButtonStyle,
    backgroundColor: 'var(--red-600)',
  };
  public destroy$: Subject<void> = new Subject();
  public isRedemptionFailed: boolean = false;
  private giftcardRedemptionDetails?: GiftCardOrder;
  private pollingInterval: number = 1000; // in milliseconds
  private maxPollingTime: number = 120000; // 2 minutes in milliseconds
  public voucherId: string = '';
  public redemptionId: string = '';
  public redemptionIdTemp: string = '';
  public pvDrawerConfig: OverrideStyleConfig = {
    borderRadius: '15px',
    overflow: 'hidden',
    minWidth: this.deviceService.isCurrentDeviceMobile() ? '100%' : '400px',
    maxHeight: '322px !important',
  };

  public tickStyle: OverrideStyleConfig = {
    height: '50px',
    width: '50px',
    fill: 'var(--claimed-green-tick)',
  };

  public constructor(
    private router: Router,
    private campaignService: CampaignService,
    private deviceService: DeviceService
  ) {}

  public goToDetailsPage(claimed: boolean, voucherId: string): void {
    this.router.navigateByUrl(
      `/details/${this.campaignId}/${this.giftcard?.id}?claimed=${claimed}&voucherid=${voucherId}`
    );
    this.showVoucherRedemptionModal = false;
    this.campaignService.redeemGv.emit(false);
  }

  public handleVoucherClaimModal(): void {
    if (this.isRedeeming) return;
    if (!this.campaignId || !this.giftcard?.id) return;
    this.isRedeeming = true;
    this.campaignService
      .redeemGiftVoucher(this.campaignId, this.giftcard?.id)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isRedeeming = false;
        })
      )
      .subscribe({
        next: (redemptionResponse: GiftCardBookingResponse) => {
          const gvRedemptionDetails =
            redemptionResponse?.data?.attributes?.gift_card_orders?.[0] ?? {};
          if (
            (gvRedemptionDetails?.status === 'success' ||
              gvRedemptionDetails?.status === 'complete') &&
            gvRedemptionDetails?.vouchers?.length
          ) {
            this.giftcardRedemptionDetails = gvRedemptionDetails;
            this.redemptionId = gvRedemptionDetails.redemption_ids?.[0];
            this.getVoucher();
            this.isRedemptionFailed = false;
          } else {
            this.isRedemptionFailed = true;
            this.isRedeeming = false;
          }
        },
        error: () => {
          this.isRedemptionFailed = true;
          this.isRedeeming = false;
        },
      });
  }

  private getVoucher(): void {
    if (!this.campaignId) return;
    this.isRedeeming = true;
    this.campaignService
      .getCampaignRedemptions(this.campaignId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (bookings: BookingResponse) => {
          if (bookings?.gift_card_bookings?.data?.length) {
            const redeemedGvVoucher: GiftCardBooking[] =
              bookings?.gift_card_bookings?.data;
            const redeemedGvVoucherObj = redeemedGvVoucher?.find(
              (redeemedGv: GiftCardBooking) =>
                redeemedGv.attributes.gift_card_orders[0]
                  .redemption_ids?.[0] === this.redemptionId
            );
            const redemptionId =
              redeemedGvVoucherObj?.attributes.gift_card_orders[0]
                .redemption_ids[0] ?? '';
            if (redemptionId == this.redemptionId) {
              this.isRedeeming = false;
              this.showVoucherRedemptionModal = false;
              this.showVoucherClaimModal = true;
            } else {
              this.pollingReqToCheckVoucher();
            }
          } else {
            this.pollingReqToCheckVoucher();
          }
        },
        error: () => {
          this.isRedeeming = false;
          this.isRedemptionFailed = true;
        },
      });
  }

  private pollingReqToCheckVoucher(): void {
    if (this.pollingInterval <= this.maxPollingTime) {
      setTimeout(() => {
        this.getVoucher();
      }, this.pollingInterval);

      // Double the polling interval for the next attempt
      this.pollingInterval *= 2;
    } else {
      this.isRedeeming = false;
      this.isRedemptionFailed = true;
    }
  }

  public handleVoucherRedemptionModal(openModal: boolean): void {
    this.showVoucherRedemptionModal = openModal;
    this.campaignService.redeemGv.emit(false);
  }
}
